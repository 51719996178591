import React from "react"
import ServiceSidebarArchitecture from "./ServiceSidebarArchitecture"
import details1 from "../../assets/images/services/banner-devops2.jpg"
import { Link } from "gatsby"

const DevOpsContent = ({ location }) => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img src={details1} alt="about" />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <ServiceSidebarArchitecture location={location} />
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <span className="sub-title">SOFTWARE ARCHITECTURE DESIGN</span>

              <h3>DevOps & Reliability</h3>
              <h5>
                Building a cloud native solution or adapting existing one to
                cloud. Design of globally reliable systems for 24/7
                availiability in various geographic zones.
              </h5>

              <p>
                Function critical systems have to be available continuously 24/7
                in spite of high user load and hardware damages. Luckily, DevOps
                processes and tools allow to build an infrastructure for your
                solution that is scalable and can quickly react to higher
                traffic and resource demand if necessary.{" "}
              </p>
              <p>
                Reliability Design however concentrates on avoiding single
                points of failure in your infrastructure. A failure of one proxy
                server should not switch off an entire SaaS business. Similarly,
                a failed database should not take down a critical service
                frontend like banking site or insurance claim request system.
                Any part of a complex software solution should be redundant and
                replaceable in case of hardware or connectivity problems.
              </p>

              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="content">
                    <h3>
                      Typical software challenges we observe regarding
                      infrastructure availability:
                    </h3>
                    <ul>
                      <li>
                        Single point of failure at system entry like load
                        balancers
                      </li>
                      <li>
                        Single point of failure in utility features like cache
                        servers, messaging systems, monitoring etc
                      </li>
                      <li>
                        Only one location for infrastructure placement, typical
                        for single cloud regions
                      </li>
                      <li>
                        No disaster recovery plan in place with rehearsals
                      </li>
                      <li>No periodic backups with restore rehearsals</li>
                      <li>
                        No quick & automatic scaling for components and
                        infrastructure provisioning
                      </li>
                      <li>
                        No Know - How Management in support groups for effective
                        production disaster risk mitigation
                      </li>
                      <li>No alerting of critical system components</li>
                    </ul>
                  </div>
                </div>
              </div>

              <p>
                If you feel that you suffer in your project due to these points,
                we can help.
              </p>

              <div className="content pt-30">
                <h3>What we can do for you:</h3>
                <ul>
                  <li>
                    Analyse current risk profile for your production operations
                    and prepare mitigation plan
                  </li>
                  <li>
                    Prepare design for efficient reliable applications that are
                    fully using modern DevOps infrastructure and can operate
                    globally
                  </li>
                  <li>
                    Create procedures for disaster recovery in production and
                    train your team with them
                  </li>
                  <li>
                    Introduce proper backup strategies for all components of
                    your solution
                  </li>
                  <li>
                    Create DevOps flows that will scale up infrastructure in
                    case of hardware failure or higher resource demand
                  </li>
                </ul>
              </div>

              <p>
                <b>Migrating to Cloud</b> - Part of our service offering is also
                application migration from On-Premise to Cloud: GCP, Azure, AWS,
                Telecom Cloud, Swiss Cloud, OVH. For details please take a look
                on DevOps in the Cloud and On-Premise services in Development
                Optimisation Area{" "}
                <Link to="/services-processes">
                  <b>->HERE</b>
                </Link>{" "}
                or contact us directly.
              </p>

              <h3 className="pt-30">Technology</h3>
              <p>
                Technologies & tools that we typically use to improve
                realibility and to build CD pipelines.
              </p>
              <ul className="technologies-features">
                <li>
                  <span>Terraform</span>
                </li>
                <li>
                  <span>Ansible</span>
                </li>
                <li>
                  <span>Kubernetes</span>
                </li>
                <li>
                  <span>ArgoCD</span>
                </li>
                <li>
                  <span>GitLab</span>
                </li>
                <li>
                  <span>Jenkins</span>
                </li>
                <li>
                  <span>Helm</span>
                </li>
                <li>
                  <span>Chef</span>
                </li>
                <li>
                  <span>Vargant</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevOpsContent
