import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import DevOpsContent from "../components/ServiceDetails/DevOpsContent"

const Details = ({ location }) => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Service DevOps & Reliability Design"
        homePageText="Home"
        homePageUrl="/"
        activePageText="DevOps & Reliability Design"
        sectionPageText="Architecture Services"
        sectionPageUrl="/services-architecture"
      />
      <DevOpsContent location={location} />
      <Footer />
    </Layout>
  )
}

export default Details
